import React, { PureComponent } from "react";
import "./styles.scss";
import ReactModal from "react-modal";
import { CloseIcon, TakePictureIcon } from "../../components/icons";
import { ErrorMessage, Formik } from "formik";
import { truckAddSchema } from "../../utils/validations";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import { truckTypes } from "../../constants/common";

export default class AddTruckComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        number: "",
        plateNumber: "",
        type: "Box Truck",
        containers: {},
        defaultOutboundWeight: "",
        customerId: this.props.customer._id,
        companyId: this.props.customer.companyId,
        containerCheck:true
      },
      allContainers: [],
      requiredFeilds: ["type", "plateNumber","containers","defaultOutboundWeight"],
      container: {},
      isLoading: false,
      isCameraOn: false,
      plateImageUrl: "",
      containersFromInput:[],
      editError:""
    };

  }

  componentDidMount = async () => {
    this.fetchContainers()
    if(this.props.isForEdit){
      const {editTruckData} = this.props
      this.setState(
        {
          initialValues:{
            ...this.state.initialValues,...editTruckData,
            type: editTruckData?.type?.length > 0 ? editTruckData?.type : "Box Truck",
            containerCheck:false,
          },
          plateImageUrl:editTruckData?.plateImageUrl,
          containerCheck:false,
          requiredFeilds:["type", "plateNumber"]
        })
    }
  };

  phoneFormate = (phone) => {
    return phone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  setInitialValues = ({
    e,
    values,
    setValues,
  }) => {
    const { name, value } = e.target;
    let macthData = {type:values?.type, plateNumber:values?.plateNumber, number:values?.number  }
    const {truckHere,containers} = this.checkTrucks({...macthData,[name]:value})
    let initialValues = this.state.initialValues
    if(truckHere.length > 0){
      if(containers.some(c => c.size === this.state.container.size)){
        this.setState({container:{}})
        values.defaultOutboundWeight = name !== "defaultOutboundWeight" && ""
        initialValues.defaultOutboundWeight = name !== "defaultOutboundWeight" && ""
      }
      this.setState({containersFromInput:containers})
    }else{
      this.setState({containersFromInput:[]})
    }
    if (name === "containers") {
      this.setState({ container: {  size: String(value), defaultWeight:this.state.container.defaultWeight ? Number(this.state.container.defaultWeight) : ""} })     }
    if (name === "defaultOutboundWeight") {
      const { container } = this.state
      this.setState({ container: { size: container.size, defaultWeight: Number(value) } })
    }
    setValues({ ...values, [name]: value })
    console.log("Ser initia values")
    this.setState({ initialValues: { ...values, [name]: value }, editError:"" });
  }

  fetchContainers = async () => {
    const { fetchContainers } = this.props;
    const {
      value: {
        data: { containers }
      }
    } = await fetchContainers();
    const allContainers = containers.map((item) => ({ ...item, defaultWeight: "" }))
    this.setState({ allContainers: allContainers ?? [] })
  }

  dataURItoBlob = dataURI => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    return formdata;
  }

  handleSubmit = async (values) => {
    this.setState({ isLoading: true })
    const { addNewTruck,uploadImage } = this.props
    const {plateImageUrl} = this.state;
    let plateImage = "";
    if (plateImageUrl !== "") {
      const img1 = await uploadImage(this.submitImages(plateImageUrl));
        plateImage = img1.value.url;
    }
    const containers = this.state.container.size ? this.state.container : { size: 30, defaultWeight: "" }
    const payload = { ...values, containers, defaultOutboundWeight: Number(values.defaultOutboundWeight ?? 0),plateImageUrl: plateImage }
    if(!values.containerCheck) {
      delete payload.containers
      delete payload.defaultOutboundWeight
    }
    delete payload.containerCheck;
    const { value } = await addNewTruck(payload)
    this.setState({ isLoading: false })
    if (value.status) {
      this.setState({ isModelOpen: false }, () => {
        this.props.customerDetails(this.props.customer.customerId)
        this.props.closeModal();
      });
    }
  }

  handleEditTruck = async (values) => {
    this.setState({ isLoading: true })
    const { updateTruck,uploadImage } = this.props
    const {plateImageUrl} = this.state;
    let plateImage = "";
    if (plateImageUrl !== "" && plateImageUrl.startsWith("data:image/")) {
      const img1 = await uploadImage(this.submitImages(plateImageUrl));
      plateImage = img1.value.url;
    }else{
      plateImage = plateImageUrl
    }
    const payload = { ...values, plateImageUrl: plateImage }
    delete payload.containers
    delete payload.defaultOutboundWeight
    delete payload.containerCheck;
    const { value } = await updateTruck(payload)
    this.setState({ isLoading: false })
    if (value.status) {
      this.setState({ isModelOpen: false }, () => {
        this.props.customerDetails(this.props.customer.customerId)
        this.props.closeModal();
      });
    }else{
      this.setState({isLoading:false,editError:value.message})
    }
  }

  checkTrucks = (macthData,trucks) => {
    const truckHere = this.props?.trucks?.filter(t => t?.type === (macthData?.type?.length > 0 ? macthData?.type : this.state?.initialValues?.truckType)
       && t?.plateNumber === macthData?.plateNumber && t?.number === macthData?.number
       )
    let containers = []
    truckHere.map((v)=> {
      containers = [...containers,...v.containers]
      return null
    })
    return {truckHere,containers}
  }
  /* checkTrucks = (macthData) => {
    const truckHere = this.props.trucks?.filter(t => t.type === macthData.type && t.plateNumber === macthData.plateNumber && t.number === macthData.number)
    let containers = []
    truckHere.map((v)=> {
      containers = [...containers,...v.containers]
      return null
    })
    return {truckHere,containers}
  } */

  checkAlready = (size)=>{
    const containers = this.state.containersFromInput.find(c => String(c.size) === String(size)) 
    return containers ? true : false
  }
  onTakePhoto = async (dataUri, field, field2) => {
    this.setState({ [field]: dataUri, [field2]: false });
  };

  render() {
    const { requiredFeilds, initialValues, allContainers, container, isLoading,isCameraOn,plateImageUrl } = this.state
    return (
      <>
        <ReactModal
          isOpen={true}
          onRequestClose={() => {
            this.setState({ isModelOpen: false }, () => {
              this.props.closeModal();
            });
          }}
          contentLabel="Please Confirm"
        >
          <div className="react-modal-dialog react-modal-dialog-centered invoice__modal">
            <div className="react-modal-header">
              <div className="btn-back-header justify-content-start">
                <button
                type="button"
                className="btn react-modal-close top-8-custom"
                onClick={() => { 
                  this.setState({ isModelOpen: false }, () => {
                    this.props.closeModal();
                  });
                }}
              >
                <CloseIcon />
              </button>
                <h5 className="react-modal-title">{this.props.isForEdit ? "Edit Truck" : "Add New Truck"}</h5>
              </div>
            </div>
            <div className="react-modal-body">
              <Formik
                initialValues={initialValues}
                validationSchema={truckAddSchema}
                onSubmit={(values, formikProps) => {
                  this.handleSubmit(values, formikProps)
                }}
              >
                {({ values, setValues }) => {
                  return (
                    <div className="w-100 p-4">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              value={values?.plateNumber}
                              onChange={(e) =>
                                this.setInitialValues({
                                  e,
                                  values,
                                  setValues,
                                })
                              }
                              className="form-control material-textfield-input"
                              name="plateNumber"
                              required
                            />
                            <ErrorMessage
                              component="span"
                              name="plateNumber"
                              className="invalid-feedback d-block"
                            />
                            <label className="material-textfield-label">
                              License Plate Number<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <select
                              className="form-control custom-select w-150 material-textfield-input pr-22"
                              required
                              value={values.type}
                              onChange={e => this.setInitialValues({ e, values, setValues })}
                              name="type"
                            >
                            {
                            truckTypes?.map((item)=>{
                            return(
                              <option value={item.value}>{item.name}</option>
                            )
                            })
                           }
                            </select>
                            <label className="material-textfield-label">
                              Truck Type<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              value={values.number}
                              onChange={(e) =>
                                this.setInitialValues({
                                  e,
                                  values,
                                  setValues,
                                })
                              }
                              className="form-control material-textfield-input"
                              name="number"
                              required
                            />
                            <ErrorMessage
                              component="span"
                              name="number"
                              className="invalid-feedback d-block"
                            />
                            <label className="material-textfield-label">
                              Truck Number
                            </label>
                          </div>
                        </div>
                      </div>
                      {!this.props.isForEdit ? <div>
                        <label className="custom-checkbox custom-checkbox-line mb-2">
                              <input
                               checked={values?.containerCheck}
                               onChange={(e) => {
                                const {checked,name} = e.target
                                setValues({...values,[name]:checked})
                                this.setState({containerCheck:checked,requiredFeilds:checked ? [...requiredFeilds,"containers","defaultOutboundWeight"] : requiredFeilds.filter(r => r !== "containers" && r !== "defaultOutboundWeight")})
                                }}
                                name="containerCheck"
                                type="checkbox"
                                className="custom-checkbox--input"
                              />
                              <span className="checkmark"></span>
                              <span className="text-decoration-none">Add default container weight</span>
                            </label>
                      </div>:""}
                      {values?.containerCheck ?<div className="row">
                         <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <select
                              className="form-control custom-select w-150 material-textfield-input pr-22"
                              value={container.size}
                              onChange={e => this.setInitialValues({ e, values, setValues })}
                              name="containers"
                            >
                              <option value="">Select Containers</option>
                              {allContainers.map((v, i) => {
                                const disabled = this.checkAlready(v.size)
                                return <option disabled={disabled} value={v.size} key={i}>{v.size}</option>
                              })}
                            </select>
                            <label className="material-textfield-label">
                              Containers<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className={"col-md-6"}>
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              value={values.defaultOutboundWeight}
                              onChange={(e) =>
                                this.setInitialValues({
                                  e,
                                  values,
                                  setValues,
                                })
                              }
                              className="form-control material-textfield-input"
                              name="defaultOutboundWeight"
                              required
                            />
                            <ErrorMessage
                              component="span"
                              name="defaultOutboundWeight"
                              className="invalid-feedback d-block"
                            />
                            <label className="material-textfield-label">
                              Default Outbound Weight<span>*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      :""}

                      <div className="row">
                        <div className="col-md-12 my-3">
                          {!isCameraOn ? (
                            <div className="truck__img w-100">
                              {plateImageUrl !== "" ? <img src={plateImageUrl} width="100%" alt="" /> : ""}
                            </div>
                          ) : (
                            <div className="truck__img w-100">
                              <Camera
                                onTakePhoto={dataUri =>
                                  this.onTakePhoto(dataUri, "plateImageUrl", "isCameraOn")
                                }
                                imageType={IMAGE_TYPES.PNG}
                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                isMaxResolution={true}
                                isImageMirror={false}
                                isSilentMode={false}
                                isDisplayStartCameraError={true}
                                isFullscreen={false}
                              />
                            </div>
                          )}
                          <br />
                          {!isCameraOn && (
                            <button
                              className="btn btn-lg btn-warning w-100 take__picture-btn"
                              type="button"
                              onClick={() => {
                                this.setState({
                                  isCameraOn: true,
                                  plateImageUrl: ""
                                });
                              }}
                            >
                              <TakePictureIcon color="#20456D" />
                              Take Picture of Vehicle
                            </button>
                          )}
                        </div>
                      </div>
                     {this.props.isForEdit ? <button onClick={() => this.handleEditTruck(values)} disabled={isLoading || requiredFeilds.some(key =>  !values[key] || Object.keys(values[key])?.length < 1)} className="btn btn-dark w-100">
                        {isLoading ? "Please Wait..." : "Save Truck"}
                      </button>:
                      <button onClick={() => this.handleSubmit(values)} disabled={requiredFeilds.some(key =>  !values[key] || Object.keys(values[key])?.length < 1)} className="btn btn-dark w-100">
                      {isLoading ? "Please Wait..." : "Add Truck"}
                    </button>
                      }
                  <span className="invalid-feedback d-block">
                        {this.state.editError ?? ""}
                  </span>
                    </div>
                  )
                }}

              </Formik>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}
