import React, { PureComponent } from "react";
import { Switch } from "antd";
import ReactModal from "react-modal";
import { CloseIcon, DeleteIconBlack } from "../../components/icons";
import { Formik, ErrorMessage } from "formik";
import { createCustomer } from "../../utils/validations";
import ErrorFocus from "../../utils/error-focus";
import InputMask from "react-input-mask";
import _ from "lodash";
import visa2 from "../../images/visa_2.svg";
import PlacesAutocomplete from "../../components/forms/PlaceSearchBox";
import AddCardPayEngineModal from "../../components/forms/addCardModalPayEngine";
import AddCardComponent from "../payabli/addNewCard";
import "./styles.scss";
import * as Yup from "yup";

export default class EditCustomer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        companyName: "",
        displayName: "",
        email: "",
        phone: "",
        fax: "",
        address: "",
        city: "",
        state: "",
        creditTerms: "0",
        invoiceDelivery: "",
        zip: "",
        paymentTerms: "",
        customerName: "",
        paymentType: "",
        dumpRates: {
          defaultOption: "",
          pricePerTonLight: "",
          pricePerTonHeavy: "",
          pricePerTonMixedCD: "",
          pricePerTonWood: "",
          pricePerTonMetal: "",
          pricePerTonRoofing: "",
          pricePerTonMasonry: "",
          pricePerTonBrick: "",
          pricePerTonDirt: "",
          pricePerTonConcrete: "",
          pricePerTonCD: "",
          pricePerTonLowRecovery: "",
          pricePerTonOpenContainer: "",
          pricePerTonClosedContainer: "",
          pricePerTonTrailer: "",
          pricePerTonAsbestos: "",
          pricePerYardLight: "",
          pricePerYardHeavy: "",
          pricePerYardMixedCD: "",
          pricePerYardWood: "",
          pricePerYardMetal: "",
          pricePerYardRoofing: "",
          pricePerYardMasonry: "",
          pricePerYardBrick: "",
          pricePerYardDirt: "",
          pricePerYardConcrete: "",
          pricePerYardCD: "",
          pricePerYardLowRecovery: "",
          pricePerYardOpenContainer: "",
          pricePerYardClosedContainer: "",
          pricePerYardTrailer: "",
          pricePerYardAsbestos: "",
        },
        capoutPrice: "",
        minimumPrice: "",
        invoiceFrequency: "auto",
        mattress: "",
        tire: "",
        sofa: "",
        tv: "",
        refrigerator: "",
        appliances: "",
        hardToHandle: "",
        overage: "",
        waitTime: "",
        travelFee: "",
        pricingTier: "",
        billingEmailAddress: "",
        billingContactName: "",
        secondaryBillingContacts:[]
      },
      paymentmethods: [],
      isCardModalOpen: false,
      user: {},
      hidePrice: false,
      isProjectRequired: false,
      isFuelSurchardeFee: true,
      includeAttachments: false,
      isEnvironmentalLoadFee: true,
      isLEEDsRequired : true,
      isInvoiceByProject: false,
      status: 1,
      _id: "",
      customerId: "",
      address: "",
      card: {},
      selectedCard: "",
      pricingTierList: [],
      cardData: [],
      invoiceDeliveryData: [],
      pleaseWait: false,
      paybliEnable: false,
      payabliInfo:{},
      paybliDetails: {},
      secondaryBillingContacts:[],
      customerPayabliData:{
        StoredMethods:[]
      }
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.fetchPricingTier();
    this.fetchInvoiceDeliveryData();
    this.getPaybliIntegration();
    const id = this.props.id;
    this.props.getMe();
    const {
      value: {
        data: { hidePrice, ...data },
      },
    } = await this.props.fetchCustomer(id);
    if(data?.customer?.payabliCustomer?.customerId){
      this.getCustomerPayment(data.customer.payabliCustomer.customerId);
    }else if(data?.customer?.paymentmethods?.length > 0){
      let card_data_arr = [...this.state.cardData]
      // eslint-disable-next-line 
      data.customer.paymentmethods && data.customer.paymentmethods.map(pm => {
        const brand = pm?.details?.brand;  
        const last_4 = pm?.details?.last4digits;
        card_data_arr.push({...pm, card_data:{brand,last_4}})
      })
      this.setState({cardData:card_data_arr})
    }
    const initialValues = { ...this.state.initialValues };
    for (let k in initialValues) {
      if (data?.customer[k]) {
        initialValues[k] = data?.customer[k];
      }
      for (let i = 0; i < data?.customer?.additionalItems?.length; i++) {
        if (data?.customer?.additionalItems[i]?.name === k) {
          initialValues[k] = data?.customer?.additionalItems[i]?.value;
        }
      }
    }
    if(data.customer.secondaryBillingContacts){
      this.setState({secondaryBillingContacts:data.customer.secondaryBillingContacts})
    }
    this.setState(
      {
        initialValues,
        _id: data.customer._id,
        hidePrice: data.customer.hidePrice,
        isProjectRequired: data.customer.isProjectRequired,
        isFuelSurchardeFee: data.customer.isFuelSurchardeFee,
        includeAttachments: data.customer.includeAttachments,
        isEnvironmentalLoadFee: data.customer.isEnvironmentalLoadFee,
        isLEEDsRequired: data?.customer?.isLEEDsRequired,
        isInvoiceByProject :data.customer.isInvoiceByProject,
        payabliInfo:_.get(data, "customer.payabliCustomer", {}),
        status: data.customer.status,
        address: data.customer.address,
        customerId: data.customer.customerId,
        paymentmethods: _.get(data, "customer.paymentmethods", []),
        selectedCard: _.get(data, "customer.paymentmethods[0].id", ""),
      },
      () => {
     
      }
    );
  };
  getPaybliIntegration = async () => {
    const {
      value: { status, data },
    } = await this.props.getPaybliIntegration(); // get paybli integration
    if (status) {
      this.setState({ paybliEnable: true, paybliDetails: data });
    }
  };

  fetchInvoiceDeliveryData = async () => {
    const {
      value: { data },
    } = await this.props.fetchInvoiceDeliveryData();
    this.setState({ invoiceDeliveryData: data });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  setDefaultOption({ e, values, setValues }) {
    const { initialValues } = this.state;
    setValues({
      ...values,
      dumpRates: {
        ...values.dumpRates,
        defaultOption: e.target.value,
        [e.target.value]: "",
      },
    });
    this.setState({
      initialValues: {
        ...initialValues,
        dumpRates: {
          ...initialValues.dumpRates,
          defaultOption: e.target.value,
          [e.target.value]: "",
        },
      },
    });
  }

  setInitialValues({ e, values, setValues }) {
    const { initialValues } = this.state;
    const targetName = e.target.name;
    const targetValue = e.target.value;
    const updatedProperties = {
      [targetName]: targetValue,
    };
    if (targetName === "paymentTerms" && targetValue === "cod") {
      updatedProperties.invoiceFrequency = "auto";
    }
    setValues({ ...values, ...updatedProperties });
    this.setState({
      initialValues: { ...initialValues, ...updatedProperties },
    });
  }

  setDefaultValues({ field, value, values, setValues }) {
    const { initialValues } = this.state;
    setValues({
      ...values,
      dumpRates: { ...values.dumpRates, [field]: value },
    });
    this.setState({
      initialValues: {
        ...initialValues,
        dumpRates: { ...initialValues.dumpRates, [field]: value },
      },
    });
  }

  setInitialValue({ field, value, values, setValues }) {
    const { initialValues } = this.state;
    setValues({ ...values, [field]: value });
    this.setState({ initialValues: { ...initialValues, [field]: value } });
  }

  setCardData(cardData) {
    const obj = {
      _id: Math.random(),
      details: {
        brand: cardData.card_data.brand,
        expMonth: cardData.card_data.exp_month,
        expYear: cardData.card_data.exp_year,
        last4digits: cardData.card_data.last_4,
        name: cardData.card_data.name,
      },
      token: cardData.token,
    };
    this.setState(
      {
        card: cardData,
        paymentmethods: [obj, ...this.state.paymentmethods],
        selectedCard: obj._id,
      },
      () => {

      }
    );
  }

  handleSubmit = async (
    {
      phone,
      capoutPrice,
      minimumPrice,
      fax,
      dumpRates,
      mattress,
      tire,
      sofa,
      tv,
      refrigerator,
      appliances,
      hardToHandle,
      overage,
      waitTime,
      travelFee,
      pricingTier,
      creditTerms,
      ...values
    },
    { setSubmitting, setErrors }
  ) => {
    const {
      // user: { companyId },
      hidePrice,
      isProjectRequired,
      isFuelSurchardeFee,
      includeAttachments,
      isEnvironmentalLoadFee,
      isLEEDsRequired,
      isInvoiceByProject,
    } = this.state;
    this.setState({ pleaseWait: true });
    setSubmitting(true);
    let objDefaultOption = {};
    let objTons = {};
    let objYards = {};
    for (let k in dumpRates) {
      if (k !== "defaultOption") {
        if (dumpRates[k] !== "") {
          let value = parseFloat(String(dumpRates[k]).replace(/[^\d]/g, ""));
          dumpRates[k] = value;
          if (k.indexOf("Ton") !== -1) {
            objTons[k] = dumpRates[k];
          } else {
            objYards[k] = dumpRates[k];
          }
        } else {
          dumpRates[k] = "";
        }
      } else {
        objDefaultOption[k] = dumpRates[k];
      }
    }
    // let finalDumpRates = {
    //   ...objDefaultOption,
    //   ...objTons,
    //   ...objYards
    // };
    // const additionalItems = [
    //   { label: "Mattress", name: "mattress", value: parseFloat(String(mattress).replace(/[^\d.]/g, "")) },
    //   { label: "Tire", name: "tire", value: parseFloat(String(tire).replace(/[^\d.]/g, "")) },
    //   { label: "Sofa", name: "sofa", value: parseFloat(String(sofa).replace(/[^\d.]/g, "")) },
    //   { label: "Tv", name: "tv", value: parseFloat(String(tv).replace(/[^\d.]/g, "")) },
    //   { label: "Refrigerator", name: "refrigerator", value: parseFloat(String(refrigerator).replace(/[^\d.]/g, "")) },
    //   { label: "Appliances", name: "appliances", value: parseFloat(String(appliances).replace(/[^\d.]/g, "")) },
    //   { label: "Hard To Handle", name: "hardToHandle", value: parseFloat(String(hardToHandle).replace(/[^\d.]/g, "")) },
    //   { label: "Overage", name: "overage", value: parseFloat(String(overage).replace(/[^\d.]/g, "")) },
    //   { label: "Wait Time", name: "waitTime", value: parseFloat(String(waitTime).replace(/[^\d.]/g, "")) },
    //   { label: "Travel Fee", name: "travelFee", value: parseFloat(String(travelFee).replace(/[^\d.]/g, "")) }
    // ];
    // const cp = String(capoutPrice).replace(/[^\d]/g, "") === "" || String(capoutPrice).replace(/[^\d]/g, "") === "0";
    // const mp = String(minimumPrice).replace(/[^\d]/g, "") === "";
    const payload = {
      ...values,
      _id: this.state._id,
      phone: phone.replace(/[^\d]/g, ""),
      fax: fax.replace(/[^\d]/g, ""),
      hidePrice,
      isProjectRequired,
      isFuelSurchardeFee,
      includeAttachments,
      isEnvironmentalLoadFee,
      isLEEDsRequired,
      isInvoiceByProject,
      card: this.state.cardData,
      pricingTier,
      creditTerms: Number(creditTerms),
      userId: this.props?.user?._id,
      payabliCustomer:this.state.payabliInfo,
      paymentType: values.paymentType === "" && values.paymentTerms === "cod"  ? "cash" : values.paymentTerms === "account" ? "" :values.paymentType,
      // dumpRates: finalDumpRates,
      // isCapoutPrice: !cp,
      // capoutPrice: cp ? 0 : String(capoutPrice).replace(/[^\d]/g, ""),
      // minimumPrice: mp ? 0 : String(minimumPrice).replace(/[^\d]/g, ""),
      // additionalItems
    };
    const {
      value: { status, message, key },
    } = await this.props.updateCustomer(payload);
    if (status) {
      this.setState({ pleaseWait: false });
      this.props.closeModal();
    } else {
      setErrors({ [key]: message });
      this.setState({pleaseWait: false})
    }
  };

  fetchPricingTier = async () => {
    const {
      value: { data },
    } = await this.props.getPricingTier();
    this.setState({ pricingTierList: data.pricingList });
  };

  assignCardData = async (cardData) => {
    this.setState({ payabliInfo: cardData, isCardModalOpen: false });
    this.getCustomerPayment(cardData?.customerId);
  };
  getCustomerPayment = async (customerId) => {
    const payload = {
      customerId: customerId,
    };
    const {
      value: { data },
    } = await this.props.getPayabliCustomerData(payload);
    this.setState({ customerPayabliData: data });
  };
  insertSlash(val) {
    return val.replace(/^(\d{2})(\d{2})/, "$1/$2");
  }

  addSecondaryBilling = ({values,setValues})=>{
  const secondaryBillingContacts = [...this.state.secondaryBillingContacts,{
  billingContactName:"",
  billingEmailAddress:""
  }]
  this.setState({secondaryBillingContacts,initialValues:{...this.state.initialValues,secondaryBillingContacts}})
  setValues({...values,secondaryBillingContacts})
  }

  removeSecondaryBilling = ({index,values,setValues})=>{
  const secondaryBillingContacts = this.state.secondaryBillingContacts
  secondaryBillingContacts.splice(index,1)
  this.setState({secondaryBillingContacts,initialValues:{...this.state.initialValues,secondaryBillingContacts}})
  setValues({...values,secondaryBillingContacts})
  }

  changeSecondaryEmail = ({index,setValues,values,e})=>{
    const {name,value} = e.target;
    const spilttedName = name.split(".")[1]
    const secondaryBillingContacts = this.state.secondaryBillingContacts
    secondaryBillingContacts[index][spilttedName] =value
    this.setState({secondaryBillingContacts,initialValues:{...this.state.initialValues,secondaryBillingContacts}})
    setValues({...values,secondaryBillingContacts})
  }

  render() {
    const { initialValues, hidePrice, isCardModalOpen, invoiceDeliveryData,isFuelSurchardeFee, isEnvironmentalLoadFee , isLEEDsRequired , isInvoiceByProject,includeAttachments ,isProjectRequired } =
      this.state;
    return (
      <div>
        <ReactModal
          isOpen={this.props.EditCustomerModelOpen}
          onRequestClose={this.props.closeModal}
        >
          <div className="react-modal-dialog react-modal-dialog-centered create__customer-modal">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Edit Customer</h5>
              <button
                onClick={() => {
                  this.props.closeModal();
                }}
                type="button"
                className="btn react-modal-close"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="edit__companyinfo-wrapper">
                <div className="custome__container">
                  {/* <div className="btn-back-header">
                  <button className="btn btn-link btn-back">
                    <span onClick={this.props.history.goBack}>
                      <BackArrowIcon />
                      Back
                    </span>
                  </button>
                </div> */}
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={createCustomer}
                    onSubmit={(values, formikProps) =>
                      this.handleSubmit(values, formikProps)
                    }
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setValues,
                    }) => {
                      if (values.paymentTerms === "account") {
                        createCustomer.fields.creditTerms =
                          Yup.string().required("Billing Terms  is required");
                        createCustomer.fields.invoiceDelivery =
                          Yup.string().required("Invoice Delivery is required");
                        if (!createCustomer._nodes.includes("creditTerms")) {
                          createCustomer._nodes.push("creditTerms");
                        }
                        if (
                          !createCustomer._nodes.includes("invoiceDelivery")
                        ) {
                          createCustomer._nodes.push("invoiceDelivery");
                        }
                      }
                      if (values.paymentTerms === "cod") {
                        if (
                          createCustomer._nodes.includes("invoiceDelivery") &&
                          createCustomer._nodes.includes("creditTerms")
                        ) {
                          let invoiceindex =
                            createCustomer._nodes.indexOf("invoiceDelivery");
                          let creditIndex =
                            createCustomer._nodes.indexOf("creditTerms");
                          if (invoiceindex !== -1 && creditIndex !== -1) {
                            createCustomer._nodes.splice(invoiceindex, 1);
                            createCustomer._nodes.splice(creditIndex, 1);
                          }
                        }
                      }
                      return (
                        <form onSubmit={handleSubmit} noValidate>
                          <ErrorFocus />
                          <div className="card">
                            {/* <div className="card-header d-flex flex-column align-items-start">
                          <div className="row">
                            <div className="col-md-12">
                              <h5 className="card-title">Create Customer</h5>
                            </div>
                          </div>
                        </div> */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.companyName}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="companyName"
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="companyName"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Company Name<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.displayName}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="displayName"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="displayName"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Display Name<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.customerName}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="customerName"
                                      required
                                    />
                                    <label className="material-textfield-label">
                                      Primary Contact Name{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.email}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="email"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="email"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Company Email<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <InputMask
                                      mask="(999) 999-9999"
                                      name="phone"
                                      maskChar=""
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.phone}
                                      className="form-control material-textfield-input"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="phone"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Phone Number<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <InputMask
                                      mask="(999) 999-9999"
                                      name="fax"
                                      maskChar=""
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.fax}
                                      className="form-control material-textfield-input"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="fax"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Fax Number<span></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.billingContactName}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="billingContactName"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="billingContactName"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Billing Contact Name<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.billingEmailAddress}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="billingEmailAddress"
                                      required={values.paymentTerms !== 'cod' || (values.paymentTerms === 'cod' && values.billingEmailAddress)}
                                    />
                                    {values.paymentTerms !== 'cod' && values.billingEmailAddress === "" && (
                                    <ErrorMessage
                                      component="span"
                                      name="billingEmailAddress"
                                      className="invalid-feedback d-block"
                                    />)}
                                    <label className="material-textfield-label">
                                      Biliing Email Address{values.paymentTerms !== 'cod' && <span>*</span>}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {values.secondaryBillingContacts?.length > 0 ?  
                                values.secondaryBillingContacts?.map((v,i)=>{
                              return <div className="row">
                                <div className="col-md-11 p-0 m-0 row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={v.billingContactName}
                                      onChange={(e) =>
                                        this.changeSecondaryEmail({
                                          e,
                                          values,
                                          setValues,
                                          index:i
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name={`secondaryBillingContacts[${i}].billingContactName`}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`secondaryBillingContacts[${i}].billingContactName`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Billing Contact Name<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield mb-1">
                                    <input
                                      type="text"
                                      value={v.billingEmailAddress}
                                       onChange={(e) =>
                                        this.changeSecondaryEmail({
                                          e,
                                          values,
                                          setValues,
                                          index:i
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name={`secondaryBillingContacts[${i}].billingEmailAddress`}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`secondaryBillingContacts[${i}].billingEmailAddress`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Biliing Email Address<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                </div>
                                <div className="col-md-1 d-flex align-items-center justify-content-center pb-3">
                                <div className="pointer" onClick={()=> this.removeSecondaryBilling({index:i,values,setValues})}>
                                <DeleteIconBlack />
                                </div>
                                </div>
                              </div>
                              })
                              :""}
                               <span
                              type="button"
                              onClick={()=> this.addSecondaryBilling({values,setValues})}
                               className="edit_btn add__container underline mb-1">
                               Add Additional Billing Contact
                               </span>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group material-textfield">
                                    <PlacesAutocomplete
                                      type="text"
                                      name="address"
                                      value={values.address}
                                      onChange={handleChange}
                                      className="form-control material-textfield-input"
                                      handleAddressChange={({
                                        address,
                                        city,
                                        state,
                                        zip,
                                      }) => {
                                                                                setValues({
                                          ...values,
                                          address,
                                          city,
                                          state,
                                          zip,
                                        });
                                        this.setState({
                                          initialValues: {
                                            ...this.state.initialValues,
                                            address,
                                            city,
                                            state,
                                            zip,
                                          },
                                        });
                                      }}
                                      setValue={(address) =>
                                        {setValues({ ...values, address });
                                         this.setState({
                                          initialValues: {
                                            ...this.state.initialValues,
                                            address,
                                          },
                                          address})}
                                      }
                                      address={this.state.address}
                                      isRequired={false}
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="address"
                                      className="invalid-feedback d-block"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.city}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="city"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="city"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      City<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.state}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="state"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="state"
                                    />
                                    <label className="material-textfield-label">
                                      State<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.zip}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="zip"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="zip"
                                    />
                                    <label className="material-textfield-label">
                                      Zip<span></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name="pricingTier"
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.pricingTier}
                                    >
                                      <option value="">select</option>
                                      {this.state.pricingTierList.length > 0 &&
                                        this.state.pricingTierList.filter(obj => obj.isActive).map(
                                          (item) => (
                                            <option value={item._id}>
                                              {item?.pricingTier}
                                            </option>
                                          )
                                        )}
                                      {/* <option value="account">Account</option> */}
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="pricingTier"
                                    />
                                    <label className="material-textfield-label">
                                      Pricing Tier<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name="paymentTerms"
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.paymentTerms}
                                    >
                                      <option value="cod">COD</option>
                                      <option value="account">Account</option>
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="invoiceFrequency"
                                    />
                                    <label className="material-textfield-label">
                                      Payment Terms <span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name="invoiceFrequency"
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.invoiceFrequency}
                                    >
                                      <option value="auto">
                                        Each Transaction
                                      </option>
                                      <option value="daily">Daily</option>
                                      {values.paymentTerms === "account" && (
                                        <option value="weekly">Weekly</option>
                                      )}
                                       {values.paymentTerms === "account" && (
                                        <option value="monthly">Monthly</option>
                                      )}
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="invoiceFrequency"
                                    />
                                    <label className="material-textfield-label">
                                      Invoice Frequency
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {values.paymentTerms === "account" && (
                                  <>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <select
                                          className="form-control custom-select w-150 material-textfield-input pr-22"
                                          name="creditTerms"
                                          onChange={(e) =>
                                            this.setInitialValues({
                                              e,
                                              values,
                                              setValues,
                                            })
                                          }
                                          value={values.creditTerms}
                                        >
                                          <option value="0">NET 0</option>
                                          <option value="15">NET 15</option>
                                          <option value="30">NET 30</option>
                                          <option value="45">NET 45</option>
                                          <option value="60">NET 60</option>
                                        </select>
                                        <ErrorMessage
                                          component="span"
                                          className="invalid-feedback d-block"
                                          name="creditTerms"
                                        />
                                        <label className="material-textfield-label">
                                          Billing Terms<span>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name="invoiceDelivery"
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.invoiceDelivery}
                                    >
                                      <option value="">select</option>
                                      {invoiceDeliveryData?.length > 0 &&
                                        invoiceDeliveryData.map(
                                          (item, index) => (
                                            <option
                                              key={index}
                                              value={item?._id}
                                            >
                                              {item?.name}
                                            </option>
                                          )
                                        )}
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="invoiceDelivery"
                                    />
                                    <label className="material-textfield-label">
                                      Invoice Delivery<span>*</span>
                                    </label>
                                  </div>
                                  {values.paymentTerms === "account" && !this.state.paybliEnable &&
                                    <>
                                        <p className="no_file-add-card">
                                      <span onClick={() => this.setState({isCardModalOpen:true})}>Add a Card</span>
                                    </p>
                                    {
                                      this.state?.cardData?.map((card, index) => {
                                        return (
                                          <p className="no_file-add-card">
                                          {card?.card_data ? card?.card_data?.brand : ""} **** {card?.card_data ? card?.card_data?.last_4 : ""}
                                        </p>
                                        )
                                      })
                                    }
                                    </>
                                }

                                {values.paymentTerms === "account" && this.state.paybliEnable &&
                                  (this.state.customerPayabliData
                                    ?.StoredMethods.length === 0 ? (
                                    <p className="no_file-add-card">
                                      <span
                                        onClick={() => {
                                          this.setState({
                                            isCardModalOpen: true,
                                          });
                                        }}
                                      >
                                        Add a Card
                                      </span>
                                    </p>
                                  ) : (
                                    <>
                                      <p className="no_file-add-card">
                                      <span
                                        onClick={() => {
                                          this.setState({
                                            isCardModalOpen: true,
                                          });
                                        }}
                                      >
                                        Add a Card
                                      </span>
                                    </p>
                                    <ul className="filter__list">
                                      {this.state.paybliEnable && this.state.customerPayabliData
                                        ?.StoredMethods.length > 0 &&
                                        this.state.customerPayabliData?.StoredMethods.map((item) => {
                                          return (
                                            <li className="filter__list-item">
                                              <span className="d-flex -align-items-center filter__check">
                                                <label className="custom-checkbox custom-checkbox-line">
                                                  <input
                                                    type="checkbox"
                                                    className="custom-checkbox--input"
                                                  />
                                                  <span className="checkmark"></span>
                                                  <p className="card__number">
                                                    <img
                                                      src={visa2}
                                                      alt=""
                                                    />
                                                    {item.MaskedAccount}{" "}
                                                    <span>
                                                      {this.insertSlash(
                                                        item.ExpDate
                                                      )}{" "}
                                                    </span>
                                                  </p>
                                                </label>
                                              </span>
                                            </li>
                                          );
                                        })}
                                        <p className="no_file-add-card mt-4">
                                    </p>
                                    </ul>
                                    </>
                                  ))}
                                </div>
                                {values.paymentTerms === "cod" && (
                                  <div className="col-md-6">
                                    <div className="form-group material-textfield">
                                      <select
                                        className="form-control custom-select w-150 material-textfield-input pr-22"
                                        name="paymentType"
                                        onChange={(e) =>
                                          this.setInitialValues({
                                            e,
                                            values,
                                            setValues,
                                          })
                                        }
                                        value={values.paymentType}
                                      >
                                       <option value="" selected disabled>select</option>
                                        <option value="cash">Cash</option>
                                        <option value="check">Check</option>
                                        <option value="credit-card">
                                          Credit Card On File
                                        </option>
                                        <option value="terminal">
                                          Credit Card On Terminal
                                        </option>
                                      </select>
                                      <label className="material-textfield-label">
                                        Payment Type
                                      </label>
                                    </div>
                                    {values.paymentType === "credit-card" &&
                                      this.state.paybliEnable &&
                                      (this.state.customerPayabliData
                                        ?.StoredMethods.length === 0 ? (
                                        <p className="no_file-add-card">
                                          <span
                                            onClick={() =>
                                              this.setState({
                                                isCardModalOpen: true,
                                              })
                                            }
                                          >
                                            Add a Card
                                          </span>
                                        </p>
                                      ) : (
                                        <>
                                         <p className="no_file-add-card">
                                          <span
                                            onClick={() =>
                                              this.setState({
                                                isCardModalOpen: true,
                                              })
                                            }
                                          >
                                            Add a Card
                                          </span>
                                        </p>
                                        <ul className="filter__list">
                                          {this.state.customerPayabliData
                                            ?.StoredMethods.length > 0 &&
                                            this.state.customerPayabliData?.StoredMethods.map(
                                              (item) => {
                                                return (
                                                  <li className="filter__list-item">
                                                    <span className="d-flex -align-items-center filter__check">
                                                      <label className="custom-checkbox custom-checkbox-line">
                                                        <input
                                                          type="checkbox"
                                                          className="custom-checkbox--input"
                                                        />
                                                        <span className="checkmark"></span>
                                                        <p className="card__number">
                                                          <img
                                                            src={visa2}
                                                            alt=""
                                                          />
                                                          {item.MaskedAccount}{" "}
                                                          <span>
                                                            {this.insertSlash(
                                                              item.ExpDate
                                                            )}{" "}
                                                          </span>
                                                        </p>
                                                      </label>
                                                    </span>
                                                  </li>
                                                );
                                              }
                                            )}
                                        </ul>
                                        </>
                                      ))}
                                    {
                                    values.paymentType === "credit-card" &&
                                      !this.state.paybliEnable &&
                                        <>
                                        <p className="no_file-add-card">
                                        <span
                                          onClick={() =>
                                            this.setState({
                                              isCardModalOpen: true,
                                            })
                                          }
                                        >
                                          Add a Card
                                        </span>
                                      </p>
                                      {
                                        this.state.cardData?.map((card, index) => {
                                         return <p className="no_file-add-card" key={index}>
                                          {card && card?.card_data
                                            ? card?.card_data?.brand
                                            : ""
                                          }{" "}
                                          ****{" "}
                                          {card &&
                                          card?.card_data
                                            ? card?.card_data?.last_4
                                            : ""}
                                        </p>
                                        })
                                      }
                                        </>
                                     }
                                  </div>
                                )}
                              </div>
                              <div className="col-md-6 p-0">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                    Hide Price
                                  </span>
                                  <Switch
                                    name="hidePrice"
                                    checked={hidePrice}
                                    onChange={() =>
                                      this.setState({ hidePrice: !hidePrice })
                                    }
                                  />
                                  <p
                                    className={
                                      hidePrice
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {hidePrice ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              { this.props?.user?.company?.isFuelSurchardeFee &&
                              <div className="col-md-6 p-0">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                  Add a Fuel Surcharge
                                  </span>
                                  <Switch
                                    name="isFuelSurchardeFee"
                                    checked={isFuelSurchardeFee}
                                    onChange={() =>
                                      this.setState({ isFuelSurchardeFee: !isFuelSurchardeFee })
                                    }
                                  />
                                  <p
                                    className={
                                      isFuelSurchardeFee
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {isFuelSurchardeFee ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                               }
                              { this.props?.user?.company?.isEnvironmentalLoadFee &&
                                <div className="col-md-6 p-0">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                  Add Environmental Load Fee
                                  </span>
                                  <Switch
                                    name="isEnvironmentalLoadFee"
                                    checked={isEnvironmentalLoadFee}
                                    onChange={() =>
                                      this.setState({ isEnvironmentalLoadFee: !isEnvironmentalLoadFee })
                                    }
                                  />
                                  <p
                                    className={
                                      isEnvironmentalLoadFee
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {isEnvironmentalLoadFee ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              }
                              <div className="col-md-6 p-0">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                  LEEDs Required
                                  </span>
                                  <Switch
                                    name="isLEEDsRequired"
                                    checked={isLEEDsRequired}
                                    onChange={() =>
                                      this.setState({ isLEEDsRequired: !isLEEDsRequired })
                                    }
                                  />
                                  <p
                                    className={
                                      isLEEDsRequired
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {isLEEDsRequired ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                                <div className="col-md-6 p-0">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                    Invoice by project
                                  </span>
                                  {this.state.initialValues.paymentTerms === "cod" ? (
                                    <Switch
                                      name="isInvoiceByProject"
                                      checked={false} 
                                      disabled 
                                    />
                                  ) : (
                                    <Switch
                                      name="isInvoiceByProject"
                                      checked={isInvoiceByProject}
                                      onChange={() =>
                                        this.setState({ isInvoiceByProject: !isInvoiceByProject })
                                      }
                                    />
                                  )}
                                  <p
                                    className={
                                      isInvoiceByProject
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {isInvoiceByProject ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6 p-0">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                  Include Order/Invoice/PR PDF attachments on emails
                                  </span>
                                  <Switch
                                    name="includeAttachments"
                                    checked={includeAttachments}
                                    onChange={() =>
                                      this.setState({ includeAttachments: !includeAttachments })
                                    }
                                  />
                                  <p
                                    className={
                                      includeAttachments
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {includeAttachments ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              <div>
                            <div className="display-team-status">
                              <span className="display-team-text">
                                Require project on orders
                              </span>
                              <Switch
                                name="isProjectRequired"
                                checked={isProjectRequired}
                                onChange={() =>
                                  this.setState({ isProjectRequired: !isProjectRequired })
                                }
                              />
                              <p
                                className={
                                  isProjectRequired
                                    ? "on-of-text text-green"
                                    : "on-of-text text-red"
                                }
                              >
                                {isProjectRequired ? "Yes" : "No"}
                              </p>
                            </div>
                          </div>
                              <button
                                disabled={this.state.pleaseWait}
                                type="submit"
                                className="btn btn-dark btn-lg w-100"
                              >
                                {this.state.pleaseWait
                                  ? "Please Wait ..."
                                  : "Save"}
                              </button>
                            </div>
                          </div>
                          {/* <DumpRates
                        {...this.props}
                        values={values}
                        setValues={setValues}
                        isSubmitting={isSubmitting}
                        setDefaultOption={e => this.setDefaultOption({ e, values, setValues })}
                        setDefaultValues={(field, value) => this.setDefaultValues({ field, value, values, setValues })}
                        setInitialValues={e => this.setInitialValues({ e, values, setValues })}
                        setInitialValue={(field, value) => this.setInitialValue({ field, value, values, setValues })}
                      /> */}
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                {isCardModalOpen && !this.state.paybliEnable && (
                  <AddCardPayEngineModal
                    isCardModalOpen={isCardModalOpen}
                    closeModal={() => this.setState({ isCardModalOpen: false })}
                    setCardData={(cardData) =>{
                      let card_data = [...this.state?.cardData]
                      card_data.push(cardData)
                      this.setState({ cardData: card_data })
                    }
                    }
                  />
                )}
                {isCardModalOpen && this.state.paybliEnable && (
                  <AddCardComponent
                    paybliDetails={this.state.paybliDetails}
                    customerData={this.state.initialValues}
                    assignCardData={this.assignCardData}
                  />
                )}
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
