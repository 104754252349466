
import React, { PureComponent } from "react";
import "../styles.scss";
import moment from "moment-timezone";
import { SyncIcon } from "../../../components/icons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PaymentReceiptDetailsComponent from "../../Billing/partials/paymentReceiptDetails";
import { RightCaretIcon, } from "../../../components/icons";
class CusromerCreditsComponent extends PureComponent {
    constructor(props) {
        const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
        super(props);
        this.state = {
            customerPaymentReceipt: [],
            syncedDataIndex: [],
            qbDetails: {},
            isQbData: false,
            paymentModal:false,
            paymentRecieptData: {},
            currentReceiptPage: 1,
            receiptskip: 0,
            receiptlimit: 11,
            isLoading : true,
            modelData:{},
            tz
        };
    }
    componentDidMount = () => {
        this.getQbDetails()
        this.getCustomerReceivePayments()
        // this.setState({ customerPaymentReceipt: this.props.customerPaymentReceipt })
    };

    getCustomerReceivePayments = async () => {
        const payload = {
            customerId: this.props.customer._id,
            limit: this.state.receiptlimit,
            skip: this.state.receiptskip
        }
        const {
            value: {
                data: { paymentInvoices}
            }
        } = await this.props.getReceivePaymentForCustomers(payload);
            this.setState({ customerPaymentReceipt: paymentInvoices , isLoading : false})
      }

    getQbDetails = async () => {
        const { value } = await this.props.getQbDetails()
        if (value?.status) {
            this.setState({ isQbData: true, qbDetails: value.data })
        }
    }

    // componentWillReceiveProps(props) {
    //     // return the new state as object, do not call .setState()
    //     this.setState({ customerPaymentReceipt: props.customerPaymentReceipt })
    // }

    onReceiptChangePage = (page) => {
        const {currentReceiptPage , receiptlimit} = this.state
        let nextPage;
        if (page === 'next') {
            nextPage = currentReceiptPage + 1;
        } else if (page === 'prev' && currentReceiptPage > 1) {
            nextPage = currentReceiptPage - 1;
        } else {
            return;
        }
        const newSkip = (nextPage - 1) * (receiptlimit-1);
        this.setState(
            { currentReceiptPage: nextPage, receiptskip: newSkip}, // Corrected variable name
            () => {
                this.getCustomerReceivePayments();
            }
        );
    };
    

    numberWithCommas = (x) => {
        var parts = x.toFixed(2).split(".");
        var num =
            parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
            (parts[1] ? "." + parts[1] : "");
        return num;
    };

    syncPostedOrder = async (index , id) => {
        this.setState(prevState => ({
            isSyncing: true,
            selectedIndex: index,
            syncedDataIndex: [...prevState.syncedDataIndex, index , id]
        }));
        const payload = {
            order: this.state.customerPaymentReceipt[index],
        };
        const { value }=await this.props.syncPostedOrder(payload);
        if(value){
        await this.getCustomerReceivePayments();
        this.setState(prevState => ({
            isSyncing: false,
            selectedIndex: -1,
            syncedDataIndex: prevState.syncedDataIndex.filter(i => i !== index && i !== id)
        }));
        }
    };
    closeModels = () => {
        this.setState({ paymentModal:false });
      };
   
      paymentMethod = (item) => {
        if (item?.paymentHistory && item?.paymentHistory?.length > 0) {
            const paymentType = item?.paymentHistory[0]?.type;
    
            if (paymentType === "wire") {
                return `Wire`;
            }
    
            if (paymentType === "credit-memo") {
                return `Credit Memo`;
            }
    
            if (paymentType === "check") {
                const paymentMethodTypeStore = item?.customerReceivePayments[0]?.paymentMethod?.PaymentMethodTypeStore;
                if (paymentMethodTypeStore && paymentMethodTypeStore?.type && paymentMethodTypeStore?.value) {
                    const { value } = paymentMethodTypeStore;
                    return `Check ${value?.slice(0, 5)}`; 
                } else {
                    return "Check";
                }
            }
            
            
            if (item?.customerReceivePayments[0]?.paymentMethod?.paymentType !== "credit-card" && item?.customerReceivePayments[0]?.paymentMethod?.paymentType !== "card") {
                return item?.customerReceivePayments[0]?.paymentMethod?.paymentType?.replace("-", " ");
            }
    
            return `CC ${item?.customerReceivePayments[0]?.paymentMethod?.PaymentMethodTypeStore?.value ? item?.customerReceivePayments[0]?.paymentMethod?.PaymentMethodTypeStore?.value?.slice(-4) : (item?.paymentHistory[0]?.paymentData?.MaskedAccount?.slice(-4) ? item?.paymentHistory[0]?.paymentData?.MaskedAccount?.slice(-4) : item?.paymentHistory[0]?.paymentData?.SaleResponse?.maskedCardNumber?.slice(-4) ?? "-")}`;
        }
        return `CC `;
      };

    openPaymentReceipt = async(item)=>{
        const payload = {
            invoiceId: item?.invoiceId,
                companyId:item?.companyId,
          };
          const {
            value: { data },
          } = await this.props.getReceivePaymentForPdf(payload);
          Object.assign(data.paymentInvoices[0], { company: data.company,customer:this.props.customer });
   this.setState({paymentRecieptData: data.paymentInvoices[0] , paymentModal:true,modelData:item})
    }

    render() {
        return (
            <>
                <div className="show-data active">
                    <div className="table-responsive">
                        <table className="table custom-table">
                            <thead>
                                <tr>
                                    <th className="first-child">Payment Receipt #</th>
                                    {/* <th className="text-center">Quickbooks Sync</th> */}
                                    <th>Quickbooks Sync</th>
                                    <th>Payment Date</th>
                                    <th>Payment Method</th>
                                    <th>Invoice #</th>
                                    <th>Payment Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.customerPaymentReceipt && this.state.customerPaymentReceipt.length > 0 ? this.state.customerPaymentReceipt.map((item, index) =>
                                   <tr>
                                        <td onClick={()=> this.openPaymentReceipt(item)} className="first-child underline">{item?.invoiceId}</td>
                                        {/* <td><SyncIcon className="icon" /></td> */}
                                        {!this.state.isQbData ?
                                            <td>
                                                <button className="btn btn-dark setupqb" onClick={() => {
                                                    this.props.navigate(
                                                        `/integrations`
                                                    );
                                                }}>Setup QB</button>
                                            </td>
                                            :
                                           
                                                <td>
                                            {(item?.isSynced ?? false) ? (
                                              <SyncIcon
                                                className="icon btn-icon green"
                                              />
                                            ) : (
                                              this.state.syncedDataIndex.includes(item?._id) ? (
                                                            <Spin
                                                                size="small"
                                                                indicator={
                                                                    <LoadingOutlined
                                                                        style={{ fontSize: 11, color: "#000" }}
                                                                        spin
                                                                    />
                                                                }
                                                            />
                                              ) : (
                                                                <SyncIcon
                                                                    onClick={() => this.syncPostedOrder(index , item?._id)}
                                                                    className="icon btn-icon red"
                                                                />
                                              )
                                            )}
                                     </td>                                          
                                                     }
                                        <td>{moment(item?.invoiceDate)?.tz(this.state?.tz)?.format('l') + " " + moment(item?.invoiceDate)?.tz(this.state?.tz)?.format('LT')}</td>
                                        <td className="text-capitalized">
                                            {this.paymentMethod(item)}
                                        </td>
                                        <td className="white-space-normal">{item.isRecivePaymentInvoicesId.length > 0 && item.isRecivePaymentInvoicesId.map((items, index) => {
                                            return (
                                                <> {index > 0 ? <span className="underline" onClick={() => { !item?.is_deleted_invoice && this.props.getinvoiceDetailData(items) }}>  , {items} </span> : <span className="underline" onClick={() => { !item?.is_deleted_invoice && this.props.getinvoiceDetailData(items) }}>  {items} </span>}</>
                                            )
                                        }

                                        )}</td>
                                        <td>${this.numberWithCommas(Number(item?.total))}</td>
                                    </tr>
                                )
                                : this.state.isLoading ? (
                                    <tr>
                                            <td colSpan={10}>
                                                <div className="empty__section">
                                                    <div className="empty__section-inner">
                                                        <Spin
                                                            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                                                            spinning={this.state.isLoading}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                    </tr>
                                  ) : (
                                    <tr>
                                        <td colSpan={10}>
                                            <div className="empty__section">
                                                <div className="empty__section-inner">
                                                    <p className="empty__text">No Payment Receipts</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                  )}                                
                            </tbody>
                        </table>
                    </div>
                            <div className="d-flex align-items-center justify-content-center text-center">
                      <button className="btn btn-dark btn-xs mr-2 btn-previous" onClick={() => this.onReceiptChangePage('prev')} disabled={this.state.currentReceiptPage <= 1 || this.state.customerPaymentReceipt.length === 0}><RightCaretIcon/>  Previous</button>
                      <button className="btn btn-dark btn-xs btn-next" onClick={() => this.onReceiptChangePage('next')} disabled={!(this.state.customerPaymentReceipt.length > 10 )}>Next  <RightCaretIcon/></button>
                    </div>
                    {this.state.paymentModal && (
                    <PaymentReceiptDetailsComponent
                       // updateinvoiceDetailData={this.updateinvoiceDetailData}
                        isModelOpen={this.state.paymentModal}
                        closeModels={this.closeModels}
                        PaymentmodelData={this.state.paymentRecieptData}
                        sendInvoiceEmail={this.props.sendInvoiceEmail}
                        printByDropDown={this.state.isPrintRecipt}
                        uploadImage={this.props.uploadImage}
                        updateInvoiceOrder={this.props.updateInvoiceOrder}
                        updateInvoicDetail={this.props.updateInvoicDetail}
                        reArrangOrdersData={this.reArrangOrdersData}
                        companyData={this.state.companyData}
                        getOrderInvoicePdf={this.props.getOrderInvoicePdf}
                        fetchOrder={this.props.fetchOrder}
                        AllData={this.state.modelData}
                        {...this.props}
                    />
                    )}
                </div>

            </>
        );
    }
}

export default CusromerCreditsComponent;
